//
// Navigation
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

@include exports("404") {
  section.error-404{
    .page-wrapper{
      font-size: 1rem;
      text-align: center;
      code{
        font-size:1rem;
      }
      h5{
        border-bottom:0;
      }
      a{
        border-bottom: 0;
      }
      ul{
        margin-left: 1rem;
        li{
          padding-bottom:rem-calc(6);
        }
      }
    }
  }
}