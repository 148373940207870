//
// Navigation
//=================================================================================================
// @globals
//
//===================================================================
@import "../base/globals";

// @variables
//
//===================================================================

// @mixins
//
//===================================================================

// @styles
//
//===================================================================

@include exports("image-and-videobox") {
  .image-and-videobox-text {
    background: white;
    border-top: 0.2rem solid $color-secondary;
    p {
      padding: $pad*1.8 !important;
    }
  }

  .image-and-videobox-img {
    .image-and-videobox-img-photo {
      height: 29.25vw;
      overflow:hidden;
      img {
        width: 100vw;
      }
    }
    .image-and-videobox-img-video{
      height: 29.25vw;
      width:99.9%;
      iframe {
        width: 100vw;
        height: 29.25vw;
      }
    }
  }

  .image-and-videobox-img-caption{
    background: $color-secondary;
    & p {
      padding:1rem !important;
      bottom: -0.3rem;
      position: relative;
    }
  }
}