//
// Navigation
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

@include exports("cookieControl") {

  .cookie-control{
    background: #fff;
    position: fixed;
    z-index: 99999;
    bottom: 20px;
    right: 20px;
    width: 450px;
    box-shadow: $box-shadow;
    border: 1px solid $gray-light;
    @media #{$large-down} {
      bottom: 0;
      right:0;
      width:100%;
      border: 0;
    }
  }

}