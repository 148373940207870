//
// Navigation
//=================================================================================================
// @globals
//
//===================================================================
@import "../base/globals";

// @variables
//
//===================================================================

// @mixins
//
//===================================================================

// @styles
//
//===================================================================

@include exports("advice-box") {
  .horizontal-stripe{
    height:3rem;
    border-bottom: solid $base-bg 3px;
   }
  .advice-box-button{
    padding:1rem 0;
  }

  .advice-box-title{
    margin: 0 3rem;
    height: 2rem;
    background: $color-primary;
    top: -2rem;
    position: relative;
    h4 {
      text-align: center;
    }
  }
}