//
// Navigation
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

@include exports("boxes") {

  .box{
    padding: 1rem;
  }
}