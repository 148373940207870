//
// Navigation
//=================================================================================================
// @globals
//
//===================================================================
@import "../base/globals";

// @variables
//
//===================================================================

// @mixins
//
//===================================================================

// @styles
//
//===================================================================

@include exports("gallery") {
  .gallery-block {
    img {
      width: 100%;
    }
    .gallery-image-text {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 3rem;
      background: $color-secondary;
      opacity: 0.9;
      margin: $pad;
      padding: $pad*1.8;
      text-align: right;
      & p.negative {
        bottom: 1.2rem;
        position: relative;
      }
    }
  }
  .standalone-gallery {
    margin: $pad*3 0;
    div {
      padding: 5px;
      text-align: center;
      img {
        max-height: 400px;
        display: inline-block;
      }
    }
  }

  .scroll-gallery-wrapper {
    .gallery-slide-element {
      width: 70rem;
      opacity: 0.4;
      &.slick-current, &.slick-active, .slick-center {
        opacity: 1;
      }
    }
    .slick-prev, .slick-next{
      &:after{
        font-size: 4rem;
      }
    }
    .slick-prev {
      z-index: 2;
      left: 9rem;
      //width: 4.3rem;
      //background: $color-secondary !important;
      &:before{
        content: "";
        display:none;
      }
      &:after{
        content: "\f053";
        color: $font-negative-color;
        font-family: "Font Awesome 5 Light";
        background: $color-secondary;
        border: 0 !important;
        //transform: translate(-1.8rem,0) !important;
      }
    }
    .slick-next{
      z-index: 2;
      right: 9rem;
      //width: 4.3rem;
      //background: $color-secondary !important;
      &:before{
        content: "\f054";
        color: $font-negative-color;
        font-family: "Font Awesome 5 Light";
        display:none;
      }
      &:after{
        content: "\f054";
        color: $font-negative-color;
        font-family: "Font Awesome 5 Light";
        border: 0 !important;
        //transform: translate(1.8rem,0) !important;
      }
    }
  }

  .slick-dots li {
    margin-top: 0 !important;
    & button {
    border-radius: 0 !important;
    background: rgba(white, .5) !important;
    border: 1px solid transparent !important;
    min-height:0px; //overrides button height
    }
    &.slick-active button {
      background: white !important;
    }
  }

  .gallery-popup-overlay{
    display:none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,.4);
    font-size:0;
    z-index: 10000;
    cursor: pointer;
    .gallery-popup-overlay-content{
      cursor: default;
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%,-50%);
      background: #fff;
      width: 1000px;
      max-width: 1000px;
      >* {
        font-size: 1rem;
        line-height: 1.6rem;
      }
      .gallery-popup-overlay-content-text{
        width:70%;
        display:inline-block;
        vertical-align: middle;
        padding-left: 1rem;
        border-left: 1px solid $color-tertiary;
        @media #{$small-only}{
          width: 100%;
          text-align:center;
          padding-left:0;
          border-left: 0 none;
        }

      }
      .gallery-popup-overlay-content-photo{
        padding-right: 1rem;
        display:inline-block;
        vertical-align: middle;
        img {
        width: 50vw;
        margin: 1rem auto;
        }
        @media #{$small-only}{
          width: 100%;
          text-align:center;
          img{
            width:80%;
            padding-right: 0;
          }
        }
      }
      .gallery-popup-overlay-content-close{
        position: absolute;
        cursor: pointer;
        top: 0;
        right: 0;
        width: 26px;
        height: 26px;
        transform: translate(50%,-50%);
        background: $color-primary;
        line-height: 25px;
        text-align: center;
        border-radius: 50%;
        .fal{
          color: #fff;
        }
      }
      @media #{$medium-down}{
        max-width: 84%;
      }
    }
  }
}