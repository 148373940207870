//
// Navigation
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

@include exports("card") {

  .slick-track .column-s-100{
    @media #{$large-down}{
      width: 96vw !important;
    }
  }

}