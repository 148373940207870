//
// Navigation
//=================================================================================================
// @globals
//
//===================================================================
@import "../base/globals";

// @variables
//
//===================================================================

// @mixins
//
//===================================================================

// @styles
//
//===================================================================

@include exports("teaserbox") {
  .teaserbox-block {
    img {
      width: 100%;
    }
    .teaserbox-block-title{
      color: $font-negative-color;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      background: $color-secondary;
      opacity: 0.9;
      padding: $pad*1.8;
      text-align: right;
      & p.negative {
        bottom: 0.3rem;
        position: relative;
      }
    }
  }

  .teaserbox-block-text {
    background: white;
    padding: $pad*1.8;
    border-bottom: 0.2rem solid $color-secondary;
  }

  .scroll-teaserbox-wrapper {
    .teaserbox-slide-element {
      width: 30rem;
      opacity: 0.6;
      &.slick-current, &.slick-active, .slick-center {
        opacity: 1;
      }
    }
  }
  //.standalone-gallery {
  //  margin: $pad*3 0;
  //  div {
  //    padding: 5px;
  //    text-align: center;
  //    img {
  //      max-height: 400px;
  //      display: inline-block;
  //    }
  //  }
  //}
  //
  //.scroll-gallery-wrapper {
  //  .gallery-slide-element {
  //    width: 70rem;
  //    opacity: 0.4;
  //    &.slick-current, &.slick-active, .slick-center {
  //      opacity: 1;
  //    }
  //  }
  //  .slick-prev, .slick-next{
  //    &:after{
  //      font-size: 4rem;
  //    }
  //  }
  //  .slick-prev {
  //    z-index: 2;
  //    left: 9rem;
  //    //width: 4.3rem;
  //    //background: $color-secondary !important;
  //    &:before{
  //      content: "";
  //      display:none;
  //    }
  //    &:after{
  //      content: "\f053";
  //      color: $font-negative-color;
  //      font-family: "Font Awesome 5 Light";
  //      background: $color-secondary;
  //      border: 0 !important;
  //      //transform: translate(-1.8rem,0) !important;
  //    }
  //  }
  //  .slick-next{
  //    z-index: 2;
  //    right: 9rem;
  //    //width: 4.3rem;
  //    //background: $color-secondary !important;
  //    &:before{
  //      content: "\f054";
  //      color: $font-negative-color;
  //      font-family: "Font Awesome 5 Light";
  //      display:none;
  //    }
  //    &:after{
  //      content: "\f054";
  //      color: $font-negative-color;
  //      font-family: "Font Awesome 5 Light";
  //      border: 0 !important;
  //      //transform: translate(1.8rem,0) !important;
  //    }
  //  }
  //}
  //
  //.slick-dots li {
  //  margin-top: 0 !important;
  //  & button {
  //  border-radius: 0 !important;
  //  background: rgba(white, .5) !important;
  //  border: 1px solid transparent !important;
  //  min-height:0px; //overrides button height
  //  }
  //  &.slick-active button {
  //    background: white !important;
  //  }
  //}
}