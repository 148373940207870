@charset "UTF-8";

$slick-font-path: "./fonts/" !default;
$slick-font-family: "Font Awesome 5 Light" !default;
$slick-loader-path: "../images/" !default;
$slick-arrow-color: $color-primary !default;
$slick-dot-color: $color-secondary !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\f053" !default;
$slick-next-character: "\f054" !default;
$slick-dot-character: "\f111" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    } @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    } @else {
        @return url($slick-font-path + $url);
    }
}
@include keyframes('hover-arrow-left'){
    from {transform:translate(0%,-50%);}
    to {transform:translate(-8%,-50%);}
}

@include keyframes('hover-arrow-right'){
    from {transform:translate(0%,-50%);}
    to {transform:translate(8%,-50%);}
}

@include exports("_slick_theme") {


    // Default Variables

    // Slick icon entity codes outputs the following
    // "\2190" outputs ascii character "←"
    // "\2192" outputs ascii character "→"
    // "\2022" outputs ascii character "•"




    /* Slider */

    .slick-list {
        .slick-loading & {
            background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
        }
    }

    /* Icons */
    @if $slick-font-family == "slick" {
        @font-face {
            font-family: "slick";
            src: slick-font-url("slick.eot");
            src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
            font-weight: normal;
            font-style: normal;
        }
    }

    /* Arrows */

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: 100px;
        width: 130px;

        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        background: transparent;
        color: transparent;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none !important;
        outline: none;
        transition: all 300ms ease-in-out;
        &:hover, &:focus {
            outline: none;
            background: transparent;
            color: transparent;
            &:before {
                opacity: $slick-opacity-on-hover;
            }
        }
        &.slick-disabled:before {
            opacity: $slick-opacity-not-active;
        }
        &:before {
            font-family: $slick-font-family;
            font-size: 30px;
            line-height: 1;
            color: $slick-arrow-color;
            opacity: $slick-opacity-default;
            transition: all 300ms ease-in-out;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .slick-prev {
        left: -150px;
        &:hover{
            animation: hover-arrow-left 1.5s infinite alternate ease-in-out;
        }
        [dir="rtl"] & {
            left: auto;
            right: -150px;
            &:hover{
                animation: hover-arrow-right 1.5s infinite alternate  ease-in-out;
            }
        }
        &:before {
            content: " ";
            border: 50px solid #ccc;
            border-left: 50px solid transparent;
            border-top: 85px solid transparent;
            border-bottom: 85px solid transparent;
            font-size: 0;
            left: 49px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            [dir="rtl"] & {
                content: $slick-next-character;
            }
        }
        &:after{
            content: " ";
            border: 50px solid #fff;
            border-left: 50px solid transparent;
            border-top: 85px solid transparent;
            border-bottom: 85px solid transparent;
            font-size: 0;
            left: 51px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .slick-next {
        left: auto;
        right: -150px;
        &:hover{
            animation: hover-arrow-right 1.5s infinite alternate  ease-in-out;
        }
        [dir="rtl"] & {
            left: -150px;
            &:hover{
                animation: hover-arrow-left 1.5s infinite alternate  ease-in-out;
            }
        }
        &:before {
            content: " ";
            border: 50px solid #ccc;
            border-right: 50px solid transparent;
            border-top: 85px solid transparent;
            border-bottom: 85px solid transparent;
            font-size: 0;
            right: 49px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            font-size: 0;
            [dir="rtl"] & {
                content: $slick-next-character;
            }
        }
        &:after{
            content: " ";
            border: 50px solid #fff;
            border-right: 50px solid transparent;
            border-top: 85px solid transparent;
            border-bottom: 85px solid transparent;
            font-size: 0;
            right: 51px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    /* Dots */

    .slick-dots {
        position: relative;
        margin-top: 1rem;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        li {
            &:before{
                content: "";
                display:none;
            }
            position: relative;
            display: inline-block;
            height: 15px;
            width: 15px;
            margin: 1rem 7px 0;
            padding: 0 !important;
            cursor: pointer;
            button {
                position: relative;
                border: 0;
                background: transparent;
                display: block;
                height: 15px;
                width: 15px;
                margin:0;
                vertical-align: top;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;
                border: 1px solid #000;
                border-radius: 50%;
                &:hover, &:focus {
                    outline: none;
                    &:before {
                        opacity: $slick-opacity-on-hover;
                    }
                }
            }
            &.slick-active button {
                background: #000;
            }
        }
    }

    .slick-slider ul li:before{
        position: absolute;
        content: "";
    }
    .slick-track{
        padding: $pad 0;
    }
    .card-slider-wrapper.full .slick-track{
        padding:0;
    }
}