//
// Navigation
//=================================================================================================
// @globals
//
//===================================================================
@import "../base/globals";

// @variables
//
//===================================================================

// @mixins
//
//===================================================================

// @styles
//
//===================================================================

@include exports("timeline") {
  * {
    box-sizing: border-box;
  }


  /* The actual timeline (the vertical ruler) */
  .timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding:1rem 0;

    li{
      opacity: 1;
      transition: all 1s linear;
      transition-property: all;
      transition-duration: 1s;
      transition-timing-function: linear;
      transition-delay: initial;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: ''!important;
    position: absolute;
    width: 6px;
    //background-color: $color-primary;
    border: $color-primary dashed 3px;
    top: 0;
    bottom: 0;
    left: 50%;
  }

  /* Container around content */
  .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
  }

  /* The circles on the timeline */
  //.container::after {
    //content: '';
    //border-radius: 50%;
    //position: absolute;
    //width: 25px;
    //height: 25px;
    //right: -12px;
    //background-color: $color-primary;
    //border: 4px solid $color-primary;
    //top: 15px;
    //z-index: 1;
  //}

  .timeline-box {
    width: 30rem;
    padding: 1rem 0 !important;
  }


  .timeline-box.more-padding{
    padding-top: 5rem !important;
    opacity: 0;
  }
  //
  //ul timeline li .timeline-box {
  //  -webkit-transition: all 0.7s ease-in-out;
  //  -moz-transition: all 0.7 ease-in-out;
  //  -ms-transition: all 0.7s ease-in-out;
  //  -o-transition: all 0.7s ease-in-out;
  //  transition: all 0.7s ease-in-out;
  //}


  /* Add arrows to the left container (pointing right) */
  .timeline-box {
    &::after{
    content: '' !important;
    position: absolute;
    background-color: #23415a;
    height: 3px;
    //margin-left: -90px;
    //margin-top: 15px;
    width: 3.2rem;
    right: -3.2rem;
    top: 50% !important;
    }


    &:nth-child(2n){
      left: auto !important;
      margin-left: 55% !important;
      &::before {
        content: '' !important;
        position: absolute;
        background-color: $color-primary;
        height: 3px;
        width: 3.2rem;
        left: -3.2rem !important;
        top: 50% !important;
      }
      &::after{
        background: $base-bg !important;
      }
    }

  }

  ///* Add arrows to the right container (pointing left) */
  //.right::before {
  //  content: '';
  //  position: absolute;
  //  background-color: $color-primary;
  //  height: 3px;
  //  margin-left: -90px;
  //  margin-top: 15px;
  //  width: 2rem;
  //  right: 35.2rem;
  //}


  /* The actual content */
  .content {
    padding: 0 2.5rem;
    background-color: transparent;
    border: $color-primary solid 5px;
    position: relative;

    .timeline-box-part{
    }

    .timeline-box-year{
      position: relative;
      top: -2rem;
      background: $base-bg;
      padding: 1rem 0 0;
      h3{
        text-align: center;
      }
    }
    .timeline-box-photo{
      img{
        width: 100%;
      }
    }

    .timeline-box-text{
      position: relative;
      bottom: -1rem;
      background: $base-bg;
      padding: 1rem 0 3rem;
    }
  }

  /* Media queries - Responsive timeline on screens less than 600px wide */
  @media all and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
      left: 31px;
    }

  }
}